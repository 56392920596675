<template>
  <b-container class="d-flex flex-wrap justify-content-lg-between">
    <div
      v-if="$route.query.status !== '409'"
      class="giftpage"
    >
      <div class="giftpage-certificate-wrapper">
        <div class="giftpage-certificate"/>
        <div class="giftpage-certificate-links">
          <b-button
            variant="outline-primary"
            class="align-self-center"
            :href="this.assemblyResponse.giftUrl"
            target="_blank"
          >
            <b-icon
              icon="download"
              class="mr-2"
            />
            Скачать сертификат
          </b-button>
        </div>
      </div>
      <div class="giftpage-content">
        <h3>Спасибо за то, что Вы с нами!</h3>
        <p>
          Скорее забирайте Ваш сертификат!
          Его можно обменять на подарочную карту более 100 различных магазинов.<br>
          Скачайте его здесь или в личном кабинете в разделе "Подарки".
        </p>
        <p>
          Покупайте и монтируйте оборудование компании «Бастион» — получайте больше призов от нас!
        </p>
        <b-button
          class="align-self-center giftpage-content-button"
          @click="$router.push('/assembly')"
        >
          Зарегистрировать другой прибор
        </b-button>
      </div>
    </div>
    <div v-else class="d-flex justify-content-lg-between
    justify-content-center align-items-center giftpage-error">
      <div class="giftpage-error-content">
        <h3>{{this.$route.query.errorText}}</h3>
        <p>{{errorMessage}}</p>
        <b-button
          class="align-self-center giftpage-content-button"
          @click="$router.push('/assembly')"
        >
          Зарегистрировать другой прибор
        </b-button>
      </div>
      <ErrorImage class="giftpage-error-image d-lg-block d-none"/>
    </div>
  </b-container>
</template>

<script>
import ErrorImage from '../assets/409.svg';

export default {
  name: 'GiftPage',
  components: {
    ErrorImage,
  },
  data() {
    return {
      errorTitles: [
        'Этот серийный номер уже зарегистрирован в акции!',
        'Ой! Прибор с таким серийным номером не найден',
        'Упс! Этот прибор не участвует в акции!',
      ],
      errorMessages: [
        'Монтируйте оборудование Бастион Вашим клиентам — получайте больше подарков от нас!',
        'Проверьте правильность указанного серийного номера или обратитесь в службу поддержки',
        'Вы можете ознакомиться с перечнем оборудования, участвующего в акции, в Правилах проведения акции.\n'
        + '\n'
        + 'Монтируйте оборудование Бастион Вашим клиентам — получайте больше подарков от нас!',
      ],
      assemblyResponse: {
        giftUrl: null,
        uuid: null,
      },
    };
  },
  computed: {
    errorMessage() {
      const messageIndex = this.errorTitles.findIndex(
        (msg) => msg === this.assemblyResponse.errorText,
      );
      return this.errorMessages[messageIndex];
    },
  },
  mounted() {
    if (this.$route.query.status === '409') {
      this.assemblyResponse = {
        errorText: this.$route.query.errorText,
        status: this.$route.query.status,
      };
    } else {
      this.assemblyResponse = JSON.parse(this.$route.query.response);
    }
  },
};
</script>

<style scoped lang="scss">
.giftpage-content-button {
    width: 100%;
    min-width: 200px;
    margin: 50px 0 50px 0;
    background-color: #0050C8;
    gap: 10px;
    border-radius: 4px;
}
.giftpage {
  display: flex;
  width: 100%;
  margin: 180px 0 50px 0;
  justify-content: space-between;
  align-items: flex-start;

  .giftpage-certificate-wrapper {
    width: 700px;
    margin-right: 30px;

    .giftpage-certificate {
      width: 700px;
      height: 463px;
      background-image: url('../assets/ozonGift.png');
      background-size: contain;
      background-repeat: no-repeat;
    }

    .giftpage-certificate-links {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span {
        width: 353px;
        height: 33px;
        padding: 7px 13px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: rgba(231, 231, 231, 0.5);
        border: 1px solid #CED4DA;
        border-radius: 4px;
      }
    }
  }
  .giftpage-content {
    width: 400px;
  }
}
.giftpage-error {
  width: 100%;
  margin: 180px 0 50px 0;
  align-items: center;
  .giftpage-error-content {
    width: 40%;
  }
  .giftpage-error-image {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .giftpage {
    .giftpage-certificate-wrapper {
      width: 300px !important;
      height: 200px !important;

      .giftpage-certificate {
        height: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
@media (max-width: 768px) {
  .giftpage {
    justify-content: flex-start;
    .giftpage-certificate-wrapper {
      width: 400px;
      height: 250px;
      .giftpage-certificate {
        height: 100%;
        margin-bottom: 20px;
      }
    }
    .giftpage-content {
      margin-top: 60px;
      width: 100%;
      h3{
        text-align: center;
      }
    }
  }
}
@media (max-width: 996px) {
  .giftpage-error {
    align-items: flex-start !important;
    margin-top: 40px;
    .giftpage-error-content {
      h3 {
        text-align: center;
      }
      .btn {
        margin-top: 0;
      }
      width: 100%;
    }
  }
}
@media (max-width: 1200px) {
  .giftpage {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;

    .giftpage-certificate-wrapper {
      margin: 0 0 20px 0;
      .giftpage-certificate {
        width: 100%;
      }
      .giftpage-certificate-links{
        flex-direction: column;
        align-items: center;
        span{
          margin-bottom: 20px;
        }
      }
    }
    .giftpage-content {
      .btn {
        margin-top: 0 !important;
      }
    }
  ;
  }
}
</style>
